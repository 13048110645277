@import '../../../public/variables.scss';

.wrapper-top {
  width: min-content;
  min-width: 100%;
  height: 100%;
}

.played-cards-container {
  width: min-content;
  min-width: 100%;
  // 20px more on the left because the right has extra 20px margin-right from cards
  padding: 0 40px 0 40px;
  height: 100%;
  position: relative;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) calc(50% - 1px),
    rgba(192, 192, 192, 1) calc(50%),
    rgba(0, 0, 0, 0) calc(50% + 1px)
  );
}

.items {
  display: flex;
  align-items: center;
  width: 100%;
}

.list {
  display: flex;
  align-items: center;
  height: 100%;
}
