.icon {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 20px;
  margin: 0px 7px;
  color: white;
  &:hover {
    color: #820458;
  }
}
