.about-ctn {
  color: white;
  padding: 20px;
  h1 {
    text-align: center;
  }
  a {
    color: lightpink;
    text-decoration: none;
  }
  p {
    font-size: 18px;
    line-height: 23px;
    margin: 15px 0;
  }
}
