.card {
  background-color: transparent;
  width: 150px;
  height: 200px;
  border-radius: 7px;
  margin: 15px 10px;
  perspective: 1000px;
  img {
    width: 144px;
    height: 150px;
    padding: 0px;
    margin: 3px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
  .show-more-icon {
    $button-size: 20px;
    position: absolute;
    font-size: $button-size;
    line-height: $button-size;
    border-radius: 50%;
    height: $button-size;
    width: $button-size;
    background-color: white;
    color: #820458;
    top: 8px;
    left: 8px;
  }
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.5s;
  transform-style: preserve-3d;
  z-index: 0;
  cursor: pointer;
  .card-description {
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    height: 100%;
    border-radius: inherit;
    background-color: bisque;
    overflow-y: auto;
    padding: 15px;
  }
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 7px;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  background-color: whitesmoke;
  z-index: 0;
}

.card-back {
  transform: rotateY(180deg);
}

.card-date {
  background-color: dodgerblue;
  color: white;
  border: 2px ridge goldenrod;
  border-radius: 12px;
  bottom: 62px;
  left: calc(71px - 23px);
  position: absolute;
  height: fit-content;
  width: 50px;
  min-width: fit-content;
  margin: 0;
  &.wrong {
    background-color: red;
  }
}
.card-description-date {
  display: block;
  background-color: dodgerblue;
  color: white;
  border: 2px ridge goldenrod;
  border-radius: 12px;
  height: fit-content;
  width: 50px;
  min-width: fit-content;
  text-align: center;
  margin-bottom: 10px;
}

.card-text {
  border: 2px ridge goldenrod;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  background-color: bisque;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96%;
  height: 60px;
  bottom: 0;
  margin: 3px;
  padding: 5px;
}

.flipped {
  transform: rotateY(180deg);
}

.wrong-effect {
  transition-delay: 0.4s;
  transform: rotateY(180deg) rotateZ(-10deg) translateY(-25px);
  .card-date {
    background-color: red;
  }
  position: relative;
  z-index: 1;
}
