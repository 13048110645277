.progress-bar {
  position: relative;
  height: 26px;
  line-height: 20px;
  background-color: rgb(199, 242, 251);
  border-radius: 10px;
  border: 3px solid rgb(105, 31, 31);
  margin: 5px 4px;
  width: 200px;
}

.progress {
  position: absolute;
  height: 100%;
  background-color: #ffcc00;
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
}

.progress-text {
  position: absolute;
  left: 40%;
  color: rgb(105, 31, 31);
  font-weight: 700;
}
