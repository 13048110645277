.panel-ctn {
  height: 100%;
  p {
    margin: 0px;
  }
}

.square-ctn {
  transform: scale(0.5);
}

.square-ctn:hover {
  transition-duration: 0.5s;
  transform: scale(0.8);
}

.square {
  aspect-ratio: 0.75;
  height: 120px;
  max-height: 20vw;
  border: 2px dashed red;
  margin: 0px 10px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.hearts {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

$heart-size: 18px;
.heart {
  background-color: red;
  display: inline-block;
  height: $heart-size;
  margin: 0 10px;
  position: relative;
  top: 0;
  transform: rotate(-45deg);
  width: $heart-size;
}

.heart:before,
.heart:after {
  content: "";
  background-color: red;
  border-radius: 50%;
  height: $heart-size;
  position: absolute;
  width: $heart-size;
}

.heart:before {
  top: calc(-1 * $heart-size / 2);
  left: 0;
}

.heart:after {
  left: calc($heart-size / 2);
  top: 0;
}

.panel-text {
  font-size: 24px;
  font-weight: 700;
  text-align: end;
  color: white;
  margin-right: 10px;
  font-family: Arial, sans-serif;
}

.panel-item-ctn {
  display: grid;
  align-items: center;
  gap: 6px;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
}

@media screen and (max-width: 600px) {
  .square-ctn {
    transform: scale(0.4);
  }

  .square-ctn:hover {
    transition-duration: 0.5s;
    transform: scale(0.7);
  }
  .panel-text {
    font-size: 20px;
  }

  .heart {
    font-size: 20px;
  }
}
