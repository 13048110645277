html,
body {
  padding: 0;
  margin: 0;
  font-family: sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: black;
  font-size: 14px;
  background: rgb(39, 68, 81);
  width: 100%;
  height: 100vh;
  overscroll-behavior-y: contain;
}

* {
  box-sizing: border-box;
}

.app-ctn {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  min-height: fit-content;
  text-align: center;
}

h1,
h2 {
  color: whitesmoke;
}
