.board-ctn {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}
.wrapper {
  display: grid;
  grid-auto-columns: auto;
  grid-template-rows: auto auto;
  width: 100%;
}

.board-header-ctn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  width: 90%;
}

.text-btn {
  background-color: #820458;
  border-radius: 4px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  padding: 4.5px 12px;
  cursor: pointer;
  margin: 5px;
  &.share-btn {
    background-color: #ffcc00;
    color: #691f1f;
    font-weight: 600;
  }
}

.endgame-btns {
  display: flex;
  justify-content: center;
}
.scorepanel-ctn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.bottom {
  grid-row: 2;
  height: 230px;
}

.top {
  grid-row: 1;
  display: flex;
  align-items: center;
  position: relative;
  overflow-x: auto;
  height: 270px;
}

.wrong-cards {
  display: flex;
  flex-direction: row;
}

.board-navbar {
  display: flex;
  min-height: 50px;
  height: fit-content;
  align-items: center;
  box-shadow: 0px 0px 10px 1px rgb(0, 36, 30);
  margin-bottom: 2vh;
  .icon {
    font-size: 24px;
    margin: 0 17px;
  }
}
