.stats-ctn {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 10px;
  row-gap: 5px;
  padding: 10px;
  color: white;
}

.stats-main-body {
  width: fit-content;
}

.stats-title {
  text-align: center;
}

.chart-ctn {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
  color: white;
  justify-content: flex-start;
  width: 90%;
}

.bar {
  background: #820458;
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  text-align: end;
  margin: 5px;
}

.stats-value {
  font-size: min(9.5vw, 50px);
  text-align: center;
  margin: 0;
}

.stats-item {
  font-size: min(4vw, 16px);
  text-align: center;
  margin: 0;
}

.mistake-item {
  margin: 0 10px 0 0;
  font-size: 15px;
}
