.home-page-ctn {
  display: flex;
  height: 100vh;
  width: 480px;
  max-width: 95vw;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;
  .main-ctn {
    width: 100%;
  }
  .footer-ctn {
    width: 100%;
    margin-bottom: 20px;
    a {
      margin-left: 10px;
      color: #fbc3e7;
      &:hover {
        text-decoration: none;
        color: #f89bd8;
      }
    }
  }
}

.home-buttons-ctn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 60px;
  width: 100%;
}

.play-button {
  background-color: #820458;
  border-radius: 4px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  padding: 4.5px 12px;
  cursor: pointer;
}

.gamemode-button {
  align-items: center;
  background-color: #006699;
  border: 2px solid #00141e;
  border-radius: 10px;
  color: #ffffff;
  display: flex;
  width: 95%;
  min-height: fit-content;
  justify-content: space-between;
  font-size: 13.3333px;
  padding: 10px 24px;
  text-align: left;
  margin: 4px;
  .title {
    color: #ffffff;
    font-size: 21px;
    font-weight: 600;
    line-height: 25.2px;
    margin: 5px 7px 7px 3px;
    padding: 0;
  }

  .subtitle {
    color: #daf3ff;
    font-size: 16px;
    margin: 0 10px 0 3px;
    padding: 0;
  }
}

.titles-ctn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
