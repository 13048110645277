.header-ctn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  border-bottom: 2px solid #f5f5f5;
  padding-bottom: 5px;
  width: 100%;
}

.chronoline-title {
  margin: 0px;
  margin-right: 10px; //remove this line when releasing about
  font-size: 35px;
}
