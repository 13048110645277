.text {
  font-size: 18px;
  line-height: 23px;
  margin: 15px 0;
}

.ctn {
  padding: 20px;
  color: white;
  width: 100%;
  h1 {
    text-align: center;
  }
  li {
    margin: 10px 0;
  }
  .underline {
    text-decoration: underline;
  }
}

.popup-content {
  border-radius: 20px;
  border: none !important;
  background: #05415f !important;
  width: 90vw !important;
  max-width: 600px !important;
  max-height: 95vh !important;
  overflow-y: auto;
}

.gif-ctn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  img {
    border: 1px solid white;
    border-radius: 3px;
    height: 250px;
    width: 200px;
  }
}
