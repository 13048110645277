.language {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  padding: 0px 20px;
}

.settings-ctn {
  padding: 10px;
  padding-bottom: 30px;
  h1 {
    text-align: center;
  }
}
